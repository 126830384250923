export const PREMIUM_STORE_DEFAULT_POINT = 20000;

export const STORE_PAGE_HEADER = [
  '매장 정보를 입력해 주세요',
  '체험단 운영 방식에 대해 알려주세요',
  '예약 여부를 정해주세요',
  '구독 플랜을 선택해 주세요',
];

export const PRODUCT_PAGE_HEADER = [
  '기본 사항을 입력하세요',
  '제품에 대해 알려주세요',
  '광고 정보를 입력하세요',
  '채널별 혜택을 입력해주세요.',
  '제품의 종류를 입력해주세요.',
  '제품의 사진을 올려주세요.',
];

export const STORE_PAGE_TITLE = ['매장 정보', '체험단 운영 방식', '예약 여부', '구독 플랜 선택'];

export const PRODUCT_PAGE_TITLE = [
  '기본 정보 입력',
  '제품 정보 입력',
  '광고 정보 입력',
  '채널 정보 입력',
  '제품 종류 입력',
  '사진 업로드',
];

export const STORE_MAX_STEP = STORE_PAGE_TITLE.length;
export const PRODUCT_MAX_STEP = PRODUCT_PAGE_TITLE.length;

export const STORE_OPERATION_TYPE = {
  MASS_REVIEW: 'MASS_REVIEW',
  BLOG_EXPOSURE: 'BLOG_EXPOSURE',
};
