<template>
  <div>
    <EventBanner v-if="$route.path === '/' && isMatching()" id="EventBanner" />
    <header>
      <div v-if="$route.path === '/regads'">
        <div class="container ads" style="justify-content: flex-start">
          <a @click="$store.commit('setShowDialog', true)">
            <img v-if="isMatching()" src="../../assets/img/matching-logo.png" class="header-logo ads" />
            <img v-else src="../../assets/img/logo.png" class="header-logo ads" />
          </a>
          <h2 v-for="(header, index) in storePageHeader" :key="header" class="hidden-sm-and-down">
            <template v-if="companyRegStep === index + 1 && (companyTemp.category || {}).firstName === '매장'">
              <span class="font-weight-bold mr-3">{{ `${index + 1}단계` }}</span>
              <span>
                {{ header }}
              </span>
            </template>
          </h2>

          <h2 v-for="(header, index) in productPageHeader" :key="header" class="hidden-sm-and-down">
            <template v-if="companyRegStep === index + 1 && (companyTemp.category || {}).firstName === '제품'">
              <span class="font-weight-bold mr-3">{{ `${index + 1}단계` }}</span>
              <span>
                {{ header }}
              </span>
            </template>
          </h2>
        </div>
      </div>
      <div v-else-if="$route.path !== '/signup/tally'">
        <div class="container" v-if="userIsAuthenticated">
          <a class="px-4 hidden-md-and-up" @click="toggleDrawer"><v-icon>mdi-menu</v-icon></a>
          <router-link
            class="pl-4 hidden-sm-and-down"
            :to="
              $route.path.indexOf('/campaign') > -1 || $route.path.indexOf('regcam') > -1 || isMatching()
                ? '/campaign/search/'
                : '/company/report/'
            ">
            <img
              v-if="isMatching()"
              src="../../assets/img/matching-logo.png"
              class="header-logo"
              :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : ''" />
            <img
              v-else
              src="../../assets/img/logo.png"
              class="header-logo"
              :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : ''" />
          </router-link>
          <div>
            <v-row class="pt-1">
              <v-col
                class="pt-2"
                v-if="points.length > 0 && propositions != undefined && user.email !== 'test@supermembers.co.kr'">
                <span style="color: #bd1328"> ₩{{ availPoints | numFormat }} </span>
                <router-link
                  :to="isMatching() ? '/campaign/charge' : '/company/charge'"
                  class="shades--text text--black">
                  충전 금액</router-link
                >
              </v-col>
              <v-col>
                <ul style="list-style: none">
                  <li>
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn text v-on="on">
                          <span v-if="head">
                            <v-icon color="secondary" size="27">mdi-account-supervisor-circle</v-icon>
                            <span v-if="user.id !== (head || {}).id">
                              <v-icon color="default-light" size="16">mdi-chevron-right</v-icon>
                            </span>
                          </span>
                          <v-icon v-if="user.id !== (head || {}).id" color="#15c072" size="27"
                            >mdi-account-circle</v-icon
                          >
                          <span class="ml-2 body-1 text-left" style="line-height: 1rem">
                            <span>{{ user.charger }}</span>
                            <span class="secondary--text" v-if="head">본사</span><br /><span
                              class="caption warm-grey--text"
                              >{{ user.name }}</span
                            >
                          </span>
                          <v-icon color="warm-grey">mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-list>
                          <v-list-item @click="changeBranch(head)">
                            <v-list-item-avatar>
                              <v-icon color="secondary" size="27">{{
                                head ? 'mdi-account-supervisor-circle' : 'mdi-account-circle'
                              }}</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title style="line-height: 1.5rem"
                                >{{ head ? (head || {}).charger : user.charger }}
                                <span class="secondary--text" v-if="head"><br />본사</span></v-list-item-title
                              >
                              <v-list-item-subtitle class="caption warm-grey--text" style="line-height: 1.5rem">{{
                                head ? head.name : user.name
                              }}</v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-if="isMatching() && isAdminMode === true"
                                @click.stop="goToAdminRoot"
                                class="caption warm-grey--text"
                                style="line-height: 1.5rem"
                                ><v-icon size="12">mdi-chevron-double-up</v-icon
                                ><span class="warm-grey--text">루트 계정으로 이동</span></v-list-item-subtitle
                              >

                              <v-list-item-subtitle
                                @click.stop=""
                                class="caption warm-grey--text"
                                style="line-height: 1.5rem"
                                ><router-link to="/logout"
                                  ><v-icon size="12">mdi-logout-variant</v-icon
                                  ><span class="warm-grey--text">로그아웃</span></router-link
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                          <v-list-item v-for="(branch, idx) in branches" :key="idx" @click="changeBranch(branch)">
                            <v-list-item-avatar>
                              <v-icon color="warm-grey" size="27">{{
                                user.id === branch.id ? 'mdi-check-circle' : 'mdi-account-circle'
                              }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title style="line-height: 1.5rem">{{ branch.charger }}</v-list-item-title>
                              <v-list-item-subtitle class="caption warm-grey--text" style="line-height: 1.5rem">{{
                                branch.name
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else class="container">
          <router-link to="/">
            <img
              :src="isMatching() ? require('../../assets/img/matching-logo.png') : require('../../assets/img/logo.png')"
              class="header-logo"
              :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : 'ml-6'" />
          </router-link>
          <ul class="header-button">
            <!--            <li v-if="!isMatching()">-->
            <!--              <router-link to="/aibaek/" class="shades&#45;&#45;text text&#45;&#45;black">-->
            <!--                AI백선생-->
            <!--              </router-link>-->
            <!--            </li>-->
            <div v-if="!isMatching()" class="w-full d-flex justify-space-between">
              <div class="d-flex ml-4">
                <li>
                  <a href="https://rank.supermembers.co.kr/" class="shades--text text--black pr-8">블로거용 사이트</a>
                </li>
                <li>
                  <router-link to="/faq/" class="shades--text text--black">자주 묻는 질문</router-link>
                </li>
              </div>
              <div class="gap-4">
                <li>
                  <router-link :to="signInLink" class="shades--text text--black pr-8">로그인</router-link>
                </li>

                <v-btn class="white-two--text mr-4" color="#EA2A3A" @click="showConsultDialog = true">
                  무료 상담
                </v-btn>

                <v-row justify="center" align="center" style="max-height: 0px">
                  <ConsultDialog :show.sync="showConsultDialog" />
                </v-row>
              </div>
            </div>
            <div v-else class="w-full d-flex justify-end">
              <li>
                <router-link to="/signin/" class="shades--text text--black mr-4">광고주 로그인</router-link>
              </li>
            </div>
          </ul>
          <div class="mobile">
            <v-btn class="white-two--text mr-3" color="#EA2A3A" @click="showConsultDialog = true" v-if="!isMatching()">
              무료 상담
            </v-btn>
            <v-icon class="mr-3" @click="toggleDrawer">mdi-menu</v-icon>
          </div>
        </div>
      </div>
    </header>
    <div id="header-mobile" :class="mobileClass" @click="toggleDrawer">
      <ul v-if="userIsAuthenticated">
        <li class="profile" v-if="$route.path.indexOf('/regcam') > -1 || isMatching()">
          <router-link to="/campaign/search/">
            <img
              :src="companyImage"
              style="width: 32px; height: 32px; border-radius: 50%; vertical-align: middle; margin-right: 10px" />
            {{ company ? company.name : '' }}
          </router-link>
        </li>
        <li class="profile" v-else>
          <router-link to="/company/report/">
            <img
              :src="companyImage"
              style="width: 32px; height: 32px; border-radius: 50%; vertical-align: middle; margin-right: 10px" />
            {{ company ? company.name : '' }}
          </router-link>
        </li>
        <div v-if="$route.path.indexOf('/campaign') > -1" class="scroll">
          <li>
            <router-link
              :to="{ name: 'Regcam', params: { channel: 'naver' } }"
              class="body-1 font-weight-bold"
              style="color: #484848"
              >새 캠페인 만들기</router-link
            >
          </li>
          <v-divider></v-divider>
          <li>
            <router-link to="/campaign/search" class="body-1 font-weight-bold" style="color: #484848"
              >블로거 검색</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/proposition" class="body-1 font-weight-bold" style="color: #484848"
              >직접 제안하기</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/autoproposition" class="body-1 font-weight-bold" style="color: #484848"
              >자동 제안하기</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/list" class="body-1 font-weight-bold" style="color: #484848"
              >블로거 캠페인 목록</router-link
            >
            <ul style="margin-top: -16px">
              <li v-for="(campaign, i) in campaigns.filter(campaign => campaign.naver)" :key="i">
                <router-link
                  :to="{
                    name: 'CampaignDetail',
                    params: { index: i, campaign: campaign },
                  }"
                  class="body-1 ml-4"
                  style="color: #484848"
                  >{{ campaign.name }}</router-link
                >
              </li>
            </ul>
          </li>
          <v-divider></v-divider>
          <li>
            <router-link to="/campaign/searchyoutube" class="body-1 font-weight-bold" style="color: #484848"
              >유튜버 검색</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/proposition" class="body-1 font-weight-bold" style="color: #484848"
              >직접 제안하기</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/list" class="body-1 font-weight-bold" style="color: #484848"
              >유튜브 캠페인 목록</router-link
            >
            <ul style="margin-top: -16px">
              <li v-for="(campaign, i) in campaigns.filter(campaign => campaign.youtube)" :key="i">
                <router-link
                  :to="{
                    name: 'CampaignDetail',
                    params: { index: i, campaign: campaign },
                  }"
                  class="body-1 ml-4"
                  style="color: #484848"
                  >{{ campaign.name }}</router-link
                >
              </li>
            </ul>
          </li>
          <v-divider></v-divider>
          <li>
            <router-link to="/campaign/searchtiktok" class="body-1 font-weight-bold" style="color: #484848"
              >틱톡커 검색</router-link
            >
          </li>
          <v-divider></v-divider>
          <li>
            <router-link to="/campaign/charge" class="body-1 font-weight-bold" style="color: #484848"
              >충전금액 관리</router-link
            >
          </li>
          <li>
            <router-link to="/campaign/editinfo" class="body-1 font-weight-bold" style="color: #484848"
              >담당자 정보수정</router-link
            >
          </li>
          <li>
            <router-link to="/logout" class="body-1 font-weight-bold" style="color: #484848">로그아웃</router-link>
          </li>
        </div>
        <div v-else class="scroll">
          <!--        <li><router-link to="/company/dashboard">알림 현황</router-link></li>-->
          <li><router-link to="/company/report">마케팅 보고서</router-link></li>
          <li>
            <router-link to="/company/contract">이용회원 관리</router-link>
          </li>
          <li><router-link to="/company/modads">광고정보 수정</router-link></li>
          <li><router-link to="/company/pay">결제 관리</router-link></li>
          <li v-if="!hideSettlement">
            <router-link to="/company/settlement">정산 관리</router-link>
          </li>
          <li>
            <router-link to="/company/editinfo">담당자 정보수정</router-link>
          </li>
          <li><router-link to="/logout">로그아웃</router-link></li>
        </div>
      </ul>
      <ul v-else>
        <!--        <li v-if="!isMatching()">-->
        <!--          <router-link to="/aibaek/">AI백선생</router-link>-->
        <!--        </li>-->
        <li v-if="!isMatching()">
          <a href="https://rank.supermembers.co.kr/">블로거용 사이트</a>
        </li>
        <li v-if="!isMatching()">
          <router-link to="/faq/">자주 묻는 질문</router-link>
        </li>
        <li v-if="!isMatching()">
          <router-link to="/signin/">로그인</router-link>
        </li>
        <li v-if="!isMatching()">
          <router-link to="/signup/">회원가입</router-link>
        </li>
        <li v-if="isMatching()">
          <router-link to="/signin/">광고주 로그인</router-link>
        </li>
      </ul>
    </div>
    <div v-if="isShowBanner() && !!partnerBanner.length" style="width: 100%; height: 80px">
      <slick ref="slick" :options="slickOptions">
        <div
          v-for="item in partnerBanner"
          :key="item.link"
          style="height: 80px; width: 100vw"
          @click="openPartnerSite(item.link)">
          <div
            :style="{
              height: '80px',
              backgroundImage: `url('${item.bg}')`,
              backgroundRepeat: 'round',
            }">
            <img style="margin: 0 auto; height: 80px" :src="$vuetify.breakpoint.mdAndUp ? item.content : item.mobile" />
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import userMixin from '../shared/userMixin';
import companyMixin from '../shared/companyMixin';
import campaignMixin from '../shared/campaignMixin';
import EventBanner from '@/components/landing/EventBanner';
import { EventBus } from '@/components/shared/event-bus';
import Slick from 'vue-slick';
import ConsultDialog from '@/components/company/ConsultDialog';
import { STORE_PAGE_HEADER, PRODUCT_PAGE_HEADER } from '@/constants/ad';

export default {
  name: 'Header',
  mixins: [userMixin, companyMixin, campaignMixin],
  components: {
    ConsultDialog,
    EventBanner,
    Slick,
  },
  data() {
    return {
      companyImage: this.isMatching() ? require('@/assets/img/matching-logo.png') : require('@/assets/img/logo.png'),
      mobileClass: ['fade'],
      menu: false,
      // screenWidth: window.innerWidth,
      propositions: [],
      propositionAutos: [],
      hideSettlement: false,
      showConsultDialog: false,
      storePageHeader: STORE_PAGE_HEADER,
      productPageHeader: PRODUCT_PAGE_HEADER,
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        variableWidth: true,
        slidesToShow: 1,
      },
      partnerBanner: [
        {
          mobile: require('@/assets/img/partner/맥형띠배너_mobile.png'),
          content: require('@/assets/img/partner/맥형띠배너_desktop.png'),
          bg: require('@/assets/img/partner/맥형띠배너bg_desktop.png'),
          link: '/partner-detail',
        },

        // {
        //   link: 'https://bit.ly/3Oi3PPY',
        //   mobile: require('@/assets/img/partner/partner1_mobile.png'),
        //   bg: require('@/assets/img/partner/partner1_bg.png'),
        //   content: require('@/assets/img/partner/partner1_content.png'),
        // },
      ],
    };
  },
  computed: {
    // availablePoint() {
    //   return (this.points[0].balance - this._.sumBy(this.propositions, 'point') - this._.sumBy(this.propositionAutos, 'budget'));
    // }
    isAdminMode() {
      return this.$store.getters.adminMode;
    },
    getAdminLoginInfo() {
      if (this.$store.getters.adminMode) {
        return this.$store.getters.adminLoginInfo;
      } else return null;
    },

    signInLink() {
      if (this.$route.path === '/payhere' || this.$route.path === '/payhere/') {
        return { path: '/signin/', query: { recommended: '페이히어' } };
      }
      return '/signin/';
    },
  },
  watch: {
    company() {
      this.companyImage =
        (this.company || {}).thumbnail ||
        (this.isMatching() ? require('@/assets/img/matching-logo.png') : require('@/assets/img/logo.png'));
    },
  },
  methods: {
    toggleDrawer() {
      let idx = this.mobileClass.indexOf('fade');
      if (idx > -1) {
        this.mobileClass.splice(idx, 1);
      } else {
        this.mobileClass.push('close');
        window.setTimeout(() => {
          let idx = this.mobileClass.indexOf('close');
          if (idx > -1) {
            this.mobileClass.splice(idx, 1);
            this.mobileClass.push('fade');
          }
        }, 300);
      }
    },
    async changeBranch(branch) {
      await this.$store.dispatch('changeUser', branch);
      location.reload();
    },
    goToAdminRoot() {
      if (!this.$store.getters.adminMode) return;

      if (
        !this.$store.getters.adminLoginInfo ||
        !this.$store.getters.adminLoginInfo.email ||
        !this.$store.getters.adminLoginInfo.password
      ) {
        this.$store.commit('setMsg', '로그인 정보가 없습니다.');
        this.$store.commit('setShowSnackbar', true);
        return;
      }

      let logoutFunc = this.$store.dispatch('logout');
      logoutFunc
        .then(() =>
          this.$store.dispatch('signUserIn', {
            email: this.$store.getters.adminLoginInfo.email,
            password: this.$store.getters.adminLoginInfo.password,
            matching: true,
          })
        )
        .then(user => {
          if (user && this.userIsAuthenticated) {
            this.axios
              .put('/company/user', {
                id: user.id,
                last_login_matching: new Date(),
              })
              .then(() => {
                this.$router.push('/campaign/search');
              });
          } else {
            this.$store.commit('setMsg', '처리 중 오류가 발생했습니다.');
            this.$store.commit('setShowSnackbar', true);
          }
        });
    },
    isMatching() {
      return process.env.VUE_APP_TARGET === 'campaign' || location.href.indexOf('superchart') > -1;
    },
    isShowBanner() {
      return !this.isMatching() && location.pathname.includes('/company');
    },
    openPartnerSite(link) {
      console.log(link);
      window.open(link, '_blank');
    },
    async updatePoint() {
      try {
        if (this.campaigns.length > 0) {
          let filterCampaign = '';
          this.campaigns.map(campaign => {
            filterCampaign += '&campaignId[]=' + campaign.id;
          });
          let propositions = await this.axios.get('/campaign/proposition/v2?status=1' + filterCampaign);
          this.propositions = propositions.data.propositions; //.filter((p) => !p.propositionAutoId);

          // let propositionAutos = await this.axios.get('/campaign/propositionAuto?status[]=1&status[]=2' + filterCampaign);
          // this.propositionAutos = propositionAutos.data.propositionAutos;
        }
        if (this.user) {
          if (this.isMatching()) {
            let points = await this.axios.get(`company/user/point?companyId=${this.user.id}&orderBy=id&direction=desc`);
            if (points.data.total > 0) {
              let prPropositions = this.propositions.filter(proposition => {
                return this._.find(this.campaigns, {
                  id: proposition.campaignId,
                  deliveryMethod: 'PR',
                });
              });
              this.$store.commit('setPoints', points.data.companyPoints);
              this.$store.commit(
                'setWaitPoints',
                this._.sumBy(this.propositions, 'point') +
                  this._.sumBy(prPropositions, 'cprice') +
                  this._.sumBy(prPropositions, 'rewardFeeAmount') /* + this._.sumBy(this.propositionAutos, 'budget')*/
              );
              this.$store.commit(
                'setAvailPoints',
                this.points[0].balance -
                  (this._.sumBy(this.propositions, 'point') +
                    this._.sumBy(prPropositions, 'cprice') +
                    this._.sumBy(
                      prPropositions,
                      'rewardFeeAmount'
                    )) /* + this._.sumBy(this.propositionAutos, 'budget')*/
              );
            } else {
              this.$store.commit('setPoints', []);
              this.$store.commit('setAvailPoints', 0);
              this.$store.commit('setWaitPoints', 0);
            }
          } else {
            let points;
            if (this.user.id) {
              points = await this.axios.get(
                `company/user/sumempoint?companyId=${this.user.id}&orderBy=id&direction=desc`
              );
            }
            if (points && points.data.total > 0) {
              this.$store.commit('setPoints', points.data.sumemPoints);
              this.$store.commit('setWaitPoints', 0);
              this.$store.commit('setAvailPoints', this.points[0].balance);
            } else {
              this.$store.commit('setPoints', []);
              this.$store.commit('setAvailPoints', 0);
              this.$store.commit('setWaitPoints', 0);
            }
          }
        }
        this.propositions = this.propositions || [];
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    await this.updatePoint();
    EventBus.$on('update:point', async () => {
      await this.updatePoint();
    });

    this.hideSettlement = this.companies.some(company => {
      return !(company.category.firstName === '제품');
    });
  },
};
</script>

<style scoped lang="scss">
header {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 5;
  margin: 0;
  background-color: #fff;
  line-height: normal;
  font-size: 13px;
}

@media (min-width: 960px) {
  header {
    min-width: 960px;
  }
  .container {
    min-width: 100%;
  }
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
  height: 64px;
}
.bg-color--white {
  background-color: #fff;
}

.bg-color--gray {
  background-color: #fdfdfd;
}

header .container.ads {
  justify-content: left;
}

.header-logo {
  margin: 16px 4px;
  height: 28px;
}
.header-logo.ads {
  margin: 16px 40px 16px 20px;
  height: 28px;
}

.header-button {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-button li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.header-button a {
  display: block;
  line-height: 52px;
  color: var(--default-color);
  font-weight: bold;
}

.header-button li:last-child a {
  margin-right: 0;
}

.header-button .disable > a {
  color: #a2a2a2;
}

.header-button li:hover > a {
  color: #ff5a5f;
}

.header-button a:hover {
  color: #ff5a5f;
}

.header-button.mobile {
  display: none;
}

#header-mobile {
  display: none;
}

#header-mobile .header-logo {
  margin-bottom: 0;
}

@media (max-width: 960px) {
  h2 {
    font-size: 14px;
  }
  .header-logo {
    height: 24px;
    margin: 18px 2px 14px 2px;
  }
  .header-logo.ads {
    margin: 11px 16px 10px 20px !important;
    height: 27px;
  }
  .header-button {
    display: none;
  }
  .header-button.mobile {
    display: inline-block;
  }
  #header-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
  }
  #header-mobile ul {
    position: fixed;
    list-style: none;
    top: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background-color: #fff;
    width: 240px;
    animation: drawer-open 0.3s;
  }
  #header-mobile li a {
    display: block;
    padding: 12px 24px;
  }
  #header-mobile ul ul {
    position: relative;
  }

  #header-mobile.fade {
    display: none;
  }
  #header-mobile.close ul {
    left: -240px;
    animation: drawer-close 0.3s;
  }
  #header-mobile .profile {
    border-bottom: 1px solid #d0d0d0;
  }
  #header-mobile .scroll {
    overflow: auto;
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: calc(100% - 60px);
  }

  @keyframes drawer-open {
    from {
      left: -240px;
    }
    to {
      left: 0;
    }
  }
  @keyframes drawer-close {
    from {
      left: 0px;
    }
    to {
      left: -240px;
    }
  }
}

::v-deep .slick-slide {
  padding: 0;
  width: 100vw;
  /* width: 100%; */
  /* height: 100%; */
}
</style>
