import Vue from 'vue';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import store from '../index';
import { EventBus } from '@/components/shared/event-bus';
import moment from 'moment';
export default {
  state: {
    user: {},
    head: {},
    branches: {},
    points: [],
    availPoints: 0,
    waitPoints: 0,
    payState: 0, // 0: init 1: detail 2: register 3: subscribe fail
    adminMode: false,
    adminLoginInfo: { email: '', password: '' },
    authInited: false,
    token: null,        // Firebase 토큰
    tokenExpiry: null,  // 토큰 만료 시간
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
      localStorage.user = payload;
    },
    setHead(state, payload) {
      state.head = payload;
      localStorage.head = payload;
    },
    setBranches(state, payload) {
      state.branches = payload;
      localStorage.branches = payload;
    },
    setPoints(state, payload) {
      state.points = payload;
      localStorage.points = payload;
    },
    setAvailPoints(state, payload) {
      state.availPoints = payload;
      localStorage.availPoints = payload;
    },
    setWaitPoints(state, payload) {
      state.waitPoints = payload;
      localStorage.waitPoints = payload;
    },
    setPayState(state, payload) {
      state.payState = payload;
    },
    setAdminMode(state, payload) {
      state.adminMode = payload;
    },
    setAdminLoginInfo(state, payload) {
      state.adminLoginInfo = {
        email: payload.email,
        password: payload.password,
      };
    },
    setToken(state, { token, expiry }) {
      state.token = token;
      state.tokenExpiry = expiry; 
    },
    setAuthInited(state, payload) {
      state.authInited = payload;
    },
    clearToken(state) {
      state.token = null;
      state.tokenExpiry = null; 
    }
  },
  actions: { 
    getAuthInited({ state }) {
      return state.authInited;
    },
    setAuthInited({ commit }, payload) {
      commit('setAuthInited', payload.inited);
      return payload.inited;
    },
    async getValidToken({ state, commit }) { 
      if (!state.token || !state.tokenExpiry || new Date().getTime() >= state.tokenExpiry) {
        const user = firebase.auth().currentUser;
        if (!user) return null; 

        let token = await user.getIdToken();
        const decodedToken = await user.getIdTokenResult();  
        let expiry = moment(decodedToken.expirationTime).unix();

        if( expiry < moment().unix() ) {
          // NOTE: 토큰 갱신
          console.log("@@ Token expired, refresh token")
          token = await user.getIdToken(true);
          const decodedToken = await user.getIdTokenResult();
          expiry =  moment(decodedToken.expirationTime).unix(); // 밀리초로 변환
        }
        
        commit('setToken', { token, expiry });
      }
      
      return state.token;
    },
    getUserInfo({ commit }, payload) {
      commit('clearError');
      return Vue.axios
        .get('/company/user?email=' + payload.email)
        .then(data => {
          let users = data.data.users.filter(user => {
            user.id *= 1;
            return true;
          });

          commit('setUser', users[0]);
          return payload;
        })
        .catch(error => {
          store.commit('setError', error);
          console.log(error);
          alert(error.message);
        });
    },
    async signUserUp({ commit }, payload) {
      commit('clearError');
      let user = {};
      let created = {};

      try {
        let users = await Vue.axios.get('/company/user?email=' + payload.email);
        if (users.data.total > 0) {
          if (payload.password1) {
            //sign up
            throw '이미 존재하는 이메일입니다.';
          } else {
            //sign in
            store.commit('setLoading', true);
            let account = await firebase
              .auth()
              .createUserWithEmailAndPassword(payload.email, payload.password1 || payload.password);
            user = users.data.users[0];
            user.email = account.user.email;
            user.uid = account.user.uid;
          }
        } else {
          if (payload.password1) {
            //sign up
            store.commit('setLoading', true);
            created = await Vue.axios.post('/company/user', payload);
            let account = await firebase
              .auth()
              .createUserWithEmailAndPassword(payload.email, payload.password1 || payload.password);
            if ((account.user || {}).email) {
              user = payload;
              user.email = account.user.email;
              user.uid = account.user.uid;
              // users = await Vue.axios.get('/company/user?email=' + payload.email);
              Vue._.merge(user, created.data);

              window.dataLayer.push({
                event: 'signupComplete',
                signup_complete_success: true,
              });
            } else {
              throw 'Error in firebase auth';
            }
          } else {
            //sign in
            throw 'There is no match email address';
          }
        }
  
        commit('setLoading', false);
        commit('setUser', user);
        return user;
      } catch (e) {
        commit('setLoading', false);
        store.commit('setError', e);
        console.log(e);
        if (created.data && created.data.id) {
          console.log(created.data.id);
          await Vue.axios.delete('/company/user?id=' + created.data.id);
        }
      }
    },
    async signUserIn({ commit, dispatch }, payload) { 
      commit('clearError');
      commit('setAdminMode', false);
      await dispatch('logout');
      let user = {};
      try {
        if (!payload.email || payload.email.length === 0) {
          return null;
        }
        let data = await Vue.axios.get('/company/user?email=' + payload.email);
        if (payload.email.indexOf('@supermembers.co.kr') > 0) {
          commit('setAdminMode', true);
          commit('setAdminLoginInfo', {
            email: payload.email,
            password: payload.password,
          });
          if (payload.userId) {
            data = await Vue.axios.get('/company/user?id=' + payload.userId);
          }
        }
        if ((data.data || {}).total >= 1) {
          Vue._.merge(user, data.data.users[0]);

          // commit('setUser', user);
          store.commit('setLoading', true);
          if (payload.persistence) {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
          } else {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
          } 

          let account = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password);
          if (payload.email.indexOf('@supermembers.co.kr') < 0) {
            user.uid = account.user.uid;
            user.email = account.user.email;
          }
          commit('setUser', user);
  
          store.commit('setLoading', false);
          if (payload.matching) {
            await dispatch('getCampaignInfo', user);
          } else {
            await dispatch('getCompanyInfo', user);
          }
          let branches = await Vue.axios.get('/company/branch?id=' + user.id);
          commit('setHead', null);
          if (branches.data.total > 0) {
            commit('setHead', user);
            commit('setBranches', branches.data.users);
          }
          EventBus.$emit('update:point');

          return user;
        } else {
          // store.commit('setMsg', '아이디 또는 비밀번호가 잘못 입력되었습니다.');
          // store.commit('setShowSnackbar', true);
          console.log('No users 1');
          return null;
        }
      } catch (e) {
        commit('setUser', {});
        store.commit('setLoading', false);
        store.commit('setError', e);
        console.log(e);
        return null;
      }
    },
    async signUserInWithToken({ commit, dispatch }, payload) {
      commit('clearError');
      await dispatch('logout');
      store.commit('setLoading', true);

      try {
        let userCredential = await firebase.auth().signInWithCustomToken(payload.token);
        store.commit('setLoading', false);
        return userCredential;
      } catch (e) {
        store.commit('setLoading', false);
        store.commit('setError', e);
        console.log(e);
        return null;
      }
    },
    signUserInGoogle({ commit, dispatch }, payload) {
      commit('clearError');
      let user = {};
      store.commit('setLoading', true);
      return firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(account => {
          user.uid = account.user.uid;
          user.email = account.user.email;
          return firebase.auth().currentUser.getIdToken(true);
        })
        .then(token => {
          Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          commit('setToken', token);
          store.commit('setLoading', false);
          return dispatch('getCompanyInfo', payload);
        })
        .then(() => {
          return user;
        })
        .catch(error => {
          console.log(error);
          store.commit('setLoading', false);
          return null;
        });
    },
    async autoSignIn({ commit, dispatch }, payload) { 
      commit('clearError');
      let user = {};
      user.uid = payload.uid;
      user.email = payload.email;
      
      try {
        if (!payload.email || payload.email.length === 0) {
          return null;
        }
        
        let data = await Vue.axios.get('/company/user?email=' + payload.email);
        if ((data.data || {}).total === 1) {
          if (data.data.users[0]) {
            Vue._.merge(user, data.data.users[0]);
          }
          commit('setUser', user);
          store.commit('setLoading', true);
          
          // NOTE: 토큰이 없거나 만료된 경우에만 갱신
          const token = await dispatch('getValidToken');
          if (token) {
            if (payload.matching) {
              await dispatch('getCampaignInfo', user);
            } else {
              await dispatch('getCompanyInfo', user);
            }
            let branches = await Vue.axios.get('/company/branch?id=' + user.id);
            commit('setHead', null);
            if (branches.data.total > 0) {
              commit('setHead', user);
              commit('setBranches', branches.data.users);
            }
            EventBus.$emit('update:point');
          }
          
          store.commit('setLoading', false);
          return user;
        }
        return null;
      } catch (error) {
        store.commit('setLoading', false);
        store.commit('setError', error);
        console.log(error);
        return null;
      }
    },
    async changeUser({ commit, dispatch }, payload) {
      commit('setUser', payload);
      await dispatch('getCompanyInfo', payload);
      await dispatch('getCampaignInfo', payload);
    },
    // eslint-disable-next-line
    resetPasswordWithEmail({ commit }, payload) {
      commit('clearError');
      const { email } = payload;
      store.commit('setLoading', true);
      return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          store.commit('setLoading', false);
          console.log('Email Sent');
          return true;
        })
        .catch(error => {
          store.commit('setLoading', false);
          store.commit('setError', error);
          console.log(error);
          return false;
        });
    },
    async logout({ commit }) {
      store.commit('setLoading', true);
      await firebase.auth().signOut();
      let delay = ms => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1000);
      commit('setUser', null);
      commit('setCompanies', []);
      commit('setCompanyIdx', 0);
      commit('clearCompanyTemp');
      commit('setCampaigns', []);
      commit('setCampaignIdx', 0);
      commit('clearCampaignTemp');
      commit('setHead', null);
      commit('setBranches', null);
      commit('setPoints', {});
      commit('availPoints', 0);
      commit('waitPoints', 0);
      commit('setPayState', 0);
      commit('setAdminMode', 0);
      commit('setReturnUrl', null);
      delete localStorage.user;
      store.commit('setLoading', false);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    head(state) {
      return state.head;
    },
    branches(state) {
      return state.branches;
    },
    points(state) {
      return state.points;
    },
    availPoints(state) {
      return state.availPoints;
    },
    waitPoints(state) {
      return state.waitPoints;
    },
    payState(state) {
      return state.payState;
    },
    adminMode(state) {
      return state.adminMode;
    },
    adminLoginInfo(state) {
      return state.adminLoginInfo;
    },
    authInited(state) {
      return state.authInited;
    },
  },
};
