import * as HackleSDK from '@hackler/javascript-sdk';

let Hackle = {};

Hackle.install = function (Vue) {
  const hackleConfig = {
    debug: false,
  };

  const hackleClient = HackleSDK.createInstance(process.env.VUE_APP_HACKLE_SDK_KEY, hackleConfig);

  // GTM 호환을 위해 window에 저장
  window.hackleClient = hackleClient;

  hackleClient.onReady(() => {
    console.log('Hackle SDK ready');
  });

  Vue.prototype.$hackle = hackleClient;
};

export default Hackle;
